@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.spf__program-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.spf__program-container_article-image {
    width: 100%;
    background: var(--color-bg);
}

.spf__program-container_article-image img {
    width: 100%;
    height: 100%;
}

.spf__program-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 1rem 1.5rem;
    height: 100%;
}

.spf__program-container_article-content p {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 800;
    line-height: 35px;
    padding-bottom: 20px;
    color: #fff;
}

.spf__program-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 300;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.spf__program-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .spf__program-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}
.spf__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.spf__brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.spf__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.spf__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.spf__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.spf__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.spf__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.spf__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .spf__cta {
        flex-direction: column;
    }

    .spf__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .spf__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .spf__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .spf__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.spf__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.spf__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.spf__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.spf__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.spf__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.spf__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .spf__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .spf__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .spf__features-container__feature {
        margin: 1rem 0;
    }
}
.spf__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.spf__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.spf__navbar-links_logo {
    margin-right: 2rem;
}

.spf__navbar-links_logo img {
    width: 100px;
    height: 64px;
}

.spf__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.spf__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.spf__navbar-links_container p,
.spf__navbar-sign p,
.spf__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.spf__navbar-sign button,
.spf__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: #ff2800;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    justify-self: right;
}

.spf__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.spf__navbar-menu svg {
    cursor: pointer;
}

.spf__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.spf__navbar-menu_container p {
    margin: 1rem 0;
}

.spf__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .spf__navbar-links_container {
        display: none;
    }

    .spf__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .spf__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .spf__navbar {
        padding: 2rem;
    }

    .spf__navbar-sign {
        display: none;
    }

    .spf__navbar-menu_container {
        top: 20px;
    }

    .spf__navbar-menu_container-links-sign {
        display: block;
    }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg 
{

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 10% 4%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 50%);

}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: auto;
  padding-bottom: 10px;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ffffff 1.84%, #ffffff 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ff0000 -13.86%, #eeff00 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-program: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

